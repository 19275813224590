@import "palettes";
@import "theme";
@import "mixins";

html,
body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
}

body {
  display: flex;
  flex-direction: column;
  font-size: 11pt;
  font-family: $sans;

  main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  section {
    .section {
      box-sizing: content-box;
      max-width: $page;
      margin: 0 auto;
      padding: 20px 20px;
      text-align: center;
    }
  }

  section:nth-of-type(even) {
    background: $white;
  }

  section:nth-of-type(even) {
    background: $gray-100;
  }

  section:last-of-type {
    flex-grow: 1;
  }
}
